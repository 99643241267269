.custom-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @media screen and (max-width: 1399px) {
    row-gap: 12px;
  }
  @media screen and (max-width: 767px) {
    row-gap: 8px;
  }
  h2 {
    color: $dark-purple;
  }
  .form-group {
    .form-label {
      position: relative;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: $gray-400;
      letter-spacing: 0;
      transform: unset;
      @media screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
      span {
        line-height: 1;
      }
    }
    .form-control {
      position: relative;
      margin: 0;
      padding: 0;
      &::after,
      &::before {
        display: none;
      }
      &.multiselect-form-control {
        .MuiInputBase-formControl {
          height: unset;
        }
      }
      #BrandColor {
        padding-left: 20px;
      }
    }
    .MuiInputBase-formControl {
      height: 46px;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid $dark-purple-100;
      background: $white;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      color: $dark-purple;
      @media screen and (max-width: 1399px) {
        height: 42px;
        padding: 10px 14px;
      }
      @media screen and (max-width: 767px) {
        height: 36px;
        padding: 8px 12px;
        font-size: 12px;
        line-height: 12px;
      }
      &::placeholder {
        display: block;
        opacity: 1 !important;
        color: $dark-purple-400;
        -webkit-text-fill-color: $dark-purple-400;
      }
      &:disabled,
      &.Mui-disabled {
        background-color: $disabled-2;
        .MuiInputAdornment-root {
          button {
            color: transparentize($black, 0.74);
          }
        }
      }
      &.MuiInputBase-multiline {
        height: 68px !important;
        overflow-y: auto !important;
        align-items: start;
        line-height: 18px;
        @media screen and (max-width: 1399px) {
          height: 64px;
        }
        @media screen and (max-width: 767px) {
          height: 60px;
          line-height: 16px;
        }
      }
    }
    &-dark {
      .MuiInputBase-formControl {
        border-color: $dark-purple-400;
        color: $dark-purple-400;
      }
    }
    input,
    .MuiSelect-select {
      padding: 0;
      height: unset;
      display: flex;
      align-items: center;
    }
    fieldset {
      border: 0;
    }
    .MuiInputAdornment-root {
      position: absolute;
      bottom: 0;
      right: 0;
      max-height: 46px;
      height: 46px;
      width: 46px;
      @media screen and (max-width: 1399px) {
        height: 42px;
        max-height: 42px;
        width: 42px;
      }
      @media screen and (max-width: 767px) {
        height: 36px;
        max-height: 36px;
        width: 36px;
      }
      button {
        color: $dark-purple-400;
        background-color: transparent;
        font-size: 20px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      .upload-icon {
        width: 46px;
        height: 46px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        input {
          height: 36px !important;
          cursor: pointer;
        }
        button {
          right: 10px !important;
          bottom: 4px;
          top: unset !important;
          cursor: pointer;
        }
      }
    }
    &.date-picker {
      .form-control {
        input {
          padding-right: 46px;
          @media screen and (max-width: 1399px) {
            padding-right: 42px;
          }
          @media screen and (max-width: 767px) {
            padding-right: 36px;
          }
        }
      }
    }
    &.select-picker {
      .form-control {
        .MuiSelect-select {
          display: flex;
          align-items: center;
          padding-right: 46px;
          @media screen and (max-width: 1399px) {
            padding-right: 42px;
          }
          @media screen and (max-width: 767px) {
            padding-right: 36px;
          }
        }
      }
    }
    .dropdown-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 46px;
      width: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      color: $dark-purple-400;
      font-size: 16px;
      @media screen and (max-width: 1399px) {
        height: 42px;
        width: 42px;
      }
      @media screen and (max-width: 767px) {
        height: 36px;
        width: 36px;
        font-size: 12px;
      }
    }
    .custom-radio {
      .custom-row {
        column-gap: 24px;
        @media screen and (max-width: 1399px) {
          column-gap: 18px;
        }
        @media screen and (max-width: 767px) {
          column-gap: 12px;
          row-gap: 8px;
        }
      }
      .custom-col {
        @media screen and (max-width: 575px) {
          width: 100%;
        }
      }
      .form-control {
        width: 100%;
        input {
          width: 0;
          height: 0;
          opacity: 0;
          // visibility: hidden;
        }
        svg {
          display: none;
        }
        .MuiFormControlLabel-label {
          height: 46px;
          padding: 12px 16px;
          border-radius: 8px;
          border: 1px solid $dark-purple-100;
          background: $white;
          font-weight: 400;
          color: $dark-purple-400 !important;
          position: relative;
          display: flex;
          flex-direction: row-reverse;
          align-items: end;
          justify-content: space-between;
          min-width: 206px;
          width: 100%;
          column-gap: 24px;
          font-size: 14px;
          line-height: 18px;
          @media screen and (max-width: 1399px) {
            height: 42px;
            padding: 10px 14px;
            column-gap: 20px;
          }
          @media screen and (max-width: 767px) {
            height: 36px;
            padding: 8px 12px;
            font-size: 12px;
            line-height: 16px;
            min-width: unset;
            column-gap: 16px;
          }
          &::before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-color: $white;
            border: 1px solid $dark-purple-100;
            transition: all ease-in-out 0.6s;
            @media screen and (max-width: 1399px) {
              width: 18px;
              height: 18px;
            }
            @media screen and (max-width: 767px) {
              width: 16px;
              height: 16px;
            }
          }
          &::after {
            content: "\f910";
            font-family: "MyMoneyMatters" !important;
            font-size: 12px;
            line-height: 12px;
            color: $white;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            padding-top: 1px;
            opacity: 0;
            visibility: hidden;
            transition: all ease-in-out 0.6s;
            @media screen and (max-width: 1399px) {
              font-size: 10px;
              line-height: 10px;
              right: 18px;
            }
            @media screen and (max-width: 767px) {
              font-size: 8px;
              line-height: 8px;
              right: 16px;
            }
          }
        }
        .MuiButtonBase-root {
          padding: 0;
          &.Mui-checked {
            & + .MuiFormControlLabel-label {
              border-color: $purple-2;
              &::before {
                border-color: $purple-2;
                background-color: $purple-2;
              }
              &::after {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  .action-btns {
    column-gap: 24px;
    padding-top: 24px;
    margin-top: 40px;
    border-top: 1px solid $dark-purple-100;
    @media screen and (max-width: 1399px) {
      column-gap: 20px;
      padding-top: 20px;
      margin-top: 32px;
    }
    @media screen and (max-width: 767px) {
      column-gap: 16px;
      margin-top: 20px;
    }
  }
  .getAddress_autocomplete_list {
    width: 400px;
    li {
      padding: 10px 5px;
    }
  }
  .MuiFormLabel-asterisk {
    color: $red;
  }
}
.Mui-error {
  &.MuiFormHelperText-root {
    color: $red !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-left: 0;
    margin-right: 0;
    padding-top: 3px;
    letter-spacing: 0.5px;
  }
}

.MuiFormControlLabel-root {
  .Mui-checked {
    color: $purple-2 !important;
  }
  .MuiCheckbox-root {
    color: #8c8c8c;
    + .MuiFormControlLabel-label {
      font-size: 14px;
      padding-top: 2px;
    }
  }
}

.custom-textarea {
  font-family: "Elza", sans-serif;
  width: 100%;
  height: 4.25rem; /* 68px */
  padding: 0.75rem 1rem; /* 12px 16px */
  font-size: 0.875rem; /* 12px */
  border-radius: 0.5rem; /* 8px */
  border: 1px solid #d1cfd5;
  outline: none;
  resize: none;
  overflow-y: auto;
  line-height: 1.125rem; /* 18px */
  background-color: #fff;
  color: #18112f;
  cursor: text;
  align-items: flex-start;

  &::placeholder {
    color: #b1afb9;
    opacity: 1;
  }

  @media screen and (max-width: 767px) {
    padding: 0.75rem 0.5rem;
    font-size: 0.75rem;
  }

}

.custom-textarea.disabled {
  background-color: #f9f6f6;
  color: rgba(0, 0, 0, 0.38);
  cursor: default;

  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
    opacity: 0.6;
  }
}

.error-text {
  color: #e32d2d !important;
  font-size: 0.75rem !important; /* 12px */
  font-weight: 400 !important;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0.1875rem; /* 3px */
  letter-spacing: 0.03125rem; /* 0.5px */
}
.custom-radio-group {
  column-gap: 24px;
  margin-left: 20px;
  @media screen and (max-width: 1399px) {
    column-gap: 20px;
  }
  @media screen and (max-width: 991px) {
    column-gap: 16px;
    margin-left: 16px;
  }
  @media screen and (max-width: 575px) {
    column-gap: 12px;
  }
}
.custom-radio {
  margin: 0;
  .MuiRadio-root {
    // display: none;
    opacity: 0;
    &.Mui-checked {
      & + .MuiFormControlLabel-label {
        &::before {
          box-shadow: 0px 0px 8px transparentize($dark-purple, 0.76);
          border-color: $purple-2;
        }
        &::after {
          background-color: $purple-2;
        }
      }
    }
  }
  &.custom-radio-md {
    .MuiFormControlLabel-label {
      &::before {
        width: 28px;
        height: 28px;
        @media screen and (max-width: 991px) {
          width: 24px;
          height: 24px;
        }
        @media screen and (max-width: 575px) {
          width: 20px;
          height: 20px;
        }
      }
      &::after {
        width: 16px;
        height: 16px;
        left: 6px;
        top: 6px;
        @media screen and (max-width: 991px) {
          width: 14px;
          height: 14px;
          left: 5px;
          top: 5px;
        }
        @media screen and (max-width: 575px) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  &.custom-radio-sm {
    .MuiFormControlLabel-label {
      &::before {
        width: 24px;
        height: 24px;
        @media screen and (max-width: 991px) {
          width: 20px;
          height: 20px;
        }
        @media screen and (max-width: 575px) {
          width: 16px;
          height: 16px;
        }
      }
      &::after {
        width: 14px;
        height: 14px;
        left: 5px;
        top: 5px;
        @media screen and (max-width: 991px) {
          width: 10px;
          height: 10px;
          top: 6px;
        }
        @media screen and (max-width: 575px) {
          width: 8px;
          height: 8px;
          left: 4px;
        }
      }
    }
  }
  .MuiFormControlLabel-label {
    display: flex;
    align-items: end;
    justify-content: center;
    font-size: 16px;
    line-height: inherit;
    column-gap: 16px;
    text-transform: capitalize;
    font-weight: 400;
    position: relative;
    @media screen and (max-width: 991px) {
      font-size: 14px;
      column-gap: 12px;
    }
    @media screen and (max-width: 575px) {
      font-size: 12px;
      column-gap: 8px;
    }
    &::before {
      content: "";
      border: 1px solid $gray-150;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0px 0px 8px transparent;
      position: relative;
      display: block;
    }
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      background-color: transparent;
      position: absolute;
    }
  }
}
