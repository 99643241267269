body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Default styles for images */
.responsive-img {
  margin-right: 25px;
  height: 80px;
  width: 100px;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .responsive-img {
    height: 40px;   
    width: 50px;    
    margin-right: 15px; 
  }
}

.responsive-img-rwm {
  margin-left: 25px;
  width: 208px;
  height: 59px;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .responsive-img-rwm {  
    width: 150px;   
    margin-left: 15px; 
    height: 40px;
  }
}

.responsive-img-pi {
  margin-left: 25px;
  width: 208px;
  height: 65px;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .responsive-img-pi {  
    width: 150px;  
    margin-left: 15px; 
    height: 55px;
  }
}

.native-only {
  user-select: none;
  -webkit-user-select: none;
}

.notch-device iframe[title="Messaging window"] {
  top: 50px !important;
  height: 94% !important;
}



